<template>
	<div>
		<frame :top1="top1" :top2="top2">
			<!-- 头部 -->
	<!-- 		<div class="head">
				<div class="left">
					<b class="left_tit">分销基本设置</b>
				</div>

				<div class="right">
					<el-button class="refresh" @click="refresh()" icon="el-icon-refresh">刷新</el-button>
				</div>
			</div> -->

			<!-- 筛选部分 -->
			<!-- 表格头部 -->
		<!-- 	<div class="table_head">
				<div class="table_head_left">
					<i class="el-icon-s-unfold"></i>
					数据列表
				</div>

			</div> -->

			<div class="table">
				<div style="margin-left: -65%;margin-bottom: 10px;">分销>基础设置</div>
<div class="bck">
				<el-form ref="form" :model="sizeForm" label-width="160px" style="margin-left: 100px;margin-top: 100px;"
					size="mini">
					<el-form-item label="基础设置" style="width: 100px;font-weight: 600;">
						<!-- <el-input v-model="sizeForm.name"  style="margin-left: 30px;"></el-input> -->
					</el-form-item>
					<el-form-item label="请选择商户:" style="width: 300px;">
						<el-select v-model="selects.name" placeholder="请选择活动区域" style="margin-left: 10px;">
							<el-option v-for="item,index in selects" :value="item.s_name" @click.native="shopType(item.storeid)" >{{item.s_name}}</el-option>
							<!-- <el-option label="区域二" value="beijing"></el-option> -->
						</el-select>
					</el-form-item>
					<el-form-item label="分销商等级:">
						<el-radio-group v-model="labelList.d_grade" size="medium" style="margin-right: 505px;">
							<el-radio border label="1">一级</el-radio>
							<el-radio border label="2">二级</el-radio>
							<el-radio border label="3">三级</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label-width="300px" label="默认佣金比例，请到【分销商等级】进行设置"
						style="width: 200px;margin-left: 155px;">
						<!-- <el-input v-model="sizeForm.name"  style="margin-left: 30px;"></el-input> -->
					</el-form-item>
					<el-form-item label="分销商提现最低金额:">
						<el-input v-model="labelList.min_fee" style="width: 200px;margin-right: 635px;"></el-input>
					</el-form-item>

					<el-form-item label="成为分销商条件:" style="width: 300px;">
						<el-select v-model="value" placeholder='请选择' style="margin-left: 10px;">
							<el-option v-for="item in retailt" :key="item.value" :value="item.label" :label="item.label" @click.native="cl(item.value)" ></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="单次消费金额:" style="width: 300px;">
						<el-input v-model="labelList.grade_where_val" style="margin-left: 10px;"></el-input>
					</el-form-item>
					<el-form-item label="佣金设置" style="width: 200px;font-weight: 600;">
						<!-- <el-input v-model="sizeForm.name"  style="margin-left: 30px;"></el-input> -->
					</el-form-item>
					<div style="display: flex;">
						<el-form-item label="默认佣金比例:">
							<!-- <el-input v-model="sizeForm.name"  style="margin-left: 30px;"></el-input> -->
						</el-form-item>
						<el-form-item label="一级分销:" style="width: 300px;margin-left: -72px;">
							<el-input v-model="labelList.o_level"></el-input>
						</el-form-item>
						<el-form-item label="二级分销:" style="width: 300px;margin-left: -72px;">
							<el-input v-model="labelList.t_level"></el-input>
						</el-form-item>
						<el-form-item label="三级分销:" style="width: 300px;margin-left: -72px;">
							<el-input v-model="labelList.th_level"></el-input>
						</el-form-item>
					</div>
					<el-form-item label="合计佣金不超过：50%" style="width: 200px;margin-left: 170px;">
						<!-- <el-input v-model="sizeForm.name"  style="margin-left: 30px;"></el-input> -->
					</el-form-item>
					<el-form-item label="默认分销商佣金比例" style="width: 200px;margin-left: 155px;">
						<!-- <el-input v-model="sizeForm.name"  style="margin-left: 30px;"></el-input> -->
					</el-form-item>
					<el-form-item size="large" style="margin-right: 600px;margin-bottom: 200px;margin-top: 50px;">
						<el-button type="danger" @click="onSubmit">立即创建</el-button>

					</el-form-item>
				</el-form>

			</div>


		</frame>
		</div>
	</div>
</template>

<script>
	import frame from "../public/Frame.vue";

	export default {
		data() {
			return {

				top1: "9-1",
				top2: ["9"],
				  retailt: [{
				          value: '1',
				          label: '单次消费金额'
				        }, {
				          value: '2',
				          label: '累计消费金额'
				        }, {
				          value: '3',
				          label: '购买指定商品'
				        }],
				        value: '',
				flag: 1,
				admin_type: "",
				xstoreid: '',
				labelList: [],
				selects: '',
				selectp: '',
				activeTitle: "",
				grade:'',
				// st:'请选择',
				//选择活动状态
				active_status: "",
				merchantShow: false,
				show: false,
				changeShow: false,
				/*  businessList: [],
				business_id: '', */
				businessList: [{}],
				business_id: "",
				storeList: [{}],
				storeid: "",
				//添加活动
				sizeForm: {
					name: "",
					merchant: "",
					date1: "",
					date2: "",
					endDate1: "",
					endDate2: "",
					delivery: "",
					type: [],
					resource: "",
					desc: "",
				},
				//修改活动

			};
		},
		methods: {

			//修改上下架

			//刷新页面按钮
			refresh() {
				location.reload();
			},





			onSubmit() {
			console.log(this.grade)
			let login_type = this.$storage.getLocal('type')
			let token = this.$storage.getLocal('token');
			this.$request.distributionSet({
				token,
				login_type:login_type,
				storeid:this.labelList.storeid,
				min_fee:this.labelList.min_fee,
				d_grade:this.labelList.d_grade,
				grade_where:this.grade,
				grade_where_val:this.labelList.grade_where_val,
				o_level:this.labelList.o_level,
				t_level:this.labelList.t_level,
				th_level:this.labelList.th_level
				}).then(r=>{
						if(r.code == 0){ 
	                         this.$notify({
	                           title: '分销基本设置',
	                           message: '修改成功',
	                           duration: 0
	                         });
					
						}
					})
			},
			shopType(value) {
				console.log(value)
				this.storeid=value
				let token = this.$storage.getLocal('token');
				this.$request.distributionSel({
					token,
					storeid: value,
					login_type: this.$storage.getLocal('type')
				}).then(r => {
					// console.log(123)
					if (r.code == 0) {
						this.labelList = r.data;
						if(r.data.grade_where=='1')
						{
							this.value='单次消费金额'
						}else if(r.data.grade_where=='2')
						{
							this.value='累计消费金融'
						}else if(r.data.grade_where=='3')
						{
							this.value='指定购买商品'
						}else
						{
							this.value=''
						}
						this.grade=r.data.grade_where
						console.log(r)
					}
				})
			},
			storeidType(value) {
				this.xstoreid = value
			},
			//编辑活动的提交按钮
			onChange() {
				if (
					this.sizeForm2.title == ""

				) {
					alert("活动名称不能为空，时间不能为空，上下架不能为空");
					console.log("不能为空");
				} else {

				}
			},
            cl(val)
			{
               this.grade=val
			}

		},

		created() {
			let login_type = this.$storage.getLocal('type')
			let token = this.$storage.getLocal('token');
			if (login_type == '1') {
		this.$request.getMerchantData({token}).then(r=>{
					console.log(123)
					if(r.code == 0){ 
						this.selects = r.data;
						console.log(r)
				
					}
				})
			} else {
				this.$request.memberLabelList({
					token,
					storeid: '',
					login_type: login_type,
					page: '1',
					limit: '10'
				}).then(r => {
					if (r.code == 0) {
						console.log(r)
						this.labelList = r.data.data
					}
				})
			}

		},
		watch: {},
		components: {
			frame,
		},
	};
</script>

<style scoped>
	.head {
		width: 100%;
		height: 45px;
		line-height: 45px;
		background-color: rgb(243, 243, 243);
	}

	.left,
	.right {
		display: inline;
	}

	.left {
		float: left;
		margin-left: 210px;
	}

	.left_tit {
		border-left: 5px solid red;
		font-size: 22px;
		padding: 5px;
	}

	.refresh {
		/* border:1px solid red ; */
		float: right;
		margin-right: 10%;
		margin-top: 2px;
	}

	.sele {
		display: flex;
		margin-top: 20px;
		margin-left: 200px;
	}

	.screen {
		border: 1px solid #ccc;
		margin-top: 15px;
		width: 85%;
		margin-left: 200px;
		height: 90px;
	}

	.screen_tit {
		height: 40px;
		background-color: rgb(243, 243, 243);
	}

	.screen_left {
		float: left;
		margin-left: 50px;
		margin-top: 10px;
	}

	.screen_right {
		float: right;
		margin-top: 6px;
		margin-right: 10px;
	}

	.screen_cont {
		margin-left: 20px;
		padding-top: 10px;
		font-size: 18px;
	}

	.transition-box {
		position: absolute;
		z-index: 2;
		top: 150px;
		left: 27%;
		width: 800px;
		height: 710px;

		box-shadow: 0px 0px 10px #888888;
		border-radius: 4px;
		background-color: #fff;
		text-align: center;
		color: #fff;
		padding: 40px 20px;
		box-sizing: border-box;
	}

	.act_tit {
		width: 800px;
		height: 50px;
		background-color: rgb(236, 128, 141);
		margin-top: -40px;
		margin-left: -20px;
		margin-bottom: 50px;
		font-size: 20px;
		line-height: 50px;
		display: flex;
		justify-content: space-between;
	}

	.table_head {
		height: 40px;
		margin-left: 200px;
		width: 85%;
		border: 1px solid #ccc;
		display: flex;
		justify-content: space-between;
		padding-top: 5px;
	}

	.table_head_left {
		margin-top: 5px;
		margin-left: 20px;
	}

	.table_head_right li {
		float: right;
		height: 30px;

		list-style: none;
		margin-right: 10px;
	}

	.block {
		margin-top: 30px;
	}

	.listcont {
		display: flex;
	}

	.shuai {
		display: flex;
		/* border:1px solid black */
	}

	.selname {
		width: 200px;
		border: 1px solid black;

	}

	.sel {
		/* margin-left: 10px; */
		margin-top: 5px;
	}
	.table
	{
	 background-color: rgb(245,247,249);
	 padding-top: 10px;
	 padding-bottom: 25px;
	}
	
	.bck
	{
		width: 85%;
		background-color: white;
		/* margin-top: 20px; */
		/* border: 1px solid red; */
		margin-left: 14%;
		/* margin-top: 20px; */
		padding-top: 20px;
		padding-bottom: 20px;
		/* line-height: 20px; */
	}
</style>
